.ripnag1{position:absolute;width:100%;z-index:95;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);border-radius:0px 0px 10px 10px;background-color:#fff;color:#000;}@media (min-width:1280px){.ripnag1{padding:0;max-width:600px;}}
.c4rln1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding-top:0;background-color:#fff;border-radius:0px 0px 10px 10px;}@media (min-width:1280px){.c4rln1{padding-left:var(--layout-pl);padding-right:var(--layout-pr);padding:20px;}}
.rwhn05o{padding-inline:30px;color:#000;padding-top:20px;}@media (min-width:1280px){.rwhn05o{padding-top:0;}}
.n14bzmkx{font-size:14px;display:none;text-transform:uppercase;}@media (min-width:900px){.n14bzmkx{display:initial;}}
.rqgcvbj{font-family:Circular Pro;font-weight:400;font-size:14px;text-transform:uppercase;}@media (min-width:1280px){.rqgcvbj{font-size:16px;}}
.r1ckczt7{display:block;color:#000000;text-align:left;font-size:16px;-webkit-text-decoration:none;text-decoration:none;line-height:1.36em;-webkit-letter-spacing:0.06em;-moz-letter-spacing:0.06em;-ms-letter-spacing:0.06em;letter-spacing:0.06em;overflow:hidden;text-overflow:ellipsis;max-width:100%;}.r1ckczt7 + .r1ckczt7{margin-top:10px;}
.i1000tn0{text-transform:uppercase;}
.s1m1ad7s.sx11gq7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:0px 0px 10px 10px;border:none;}
.n1v3x6i6{text-transform:uppercase;text-align:center;color:#000;font-size:14px;}
.f1xhiolp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;font-size:14px;padding-block:20px;border-top:1px solid #000000;border-bottom:1px solid #000000;padding-inline:30px;}
