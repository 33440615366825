@import "../../styles/variables";

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: 1px solid #9e9f9e;
  background-color: $secondary-color;
}

.input:checked ~ .checkmark::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  flex-shrink: 0;

  background-color: #9e9f9e;
}

.input:checked ~ .checkmark {
  border: 0;
}

.filled {
  .input:checked ~ .checkmark::after {
    width: 100%;
    height: 100%;
  }
}

:global(.dark) {
  & .checkmark {
    border: 1px solid white;
    background-color: transparent;
  }

  & .input:checked ~ .checkmark::after {
    // border-color: white;
    background-color: white;
  }
}
