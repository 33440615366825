.r9odr23.sx11gq7{position:fixed;left:0;top:var(--header-height);width:100%;z-index:100;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:0 18px;}@media (min-width:1280px){.r9odr23.sx11gq7{padding:0 30px;}}.dark .r9odr23.sx11gq7{background-color:#464646;}
.i191uozw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;}
.m49ckhq.s1noqf0t{font-size:8px;margin-left:5px;margin-right:5px;}
.n1pelsnz{font-size:12px;font-weight:normal;font-family:"Open Sans";-webkit-letter-spacing:0.35px;-moz-letter-spacing:0.35px;-ms-letter-spacing:0.35px;letter-spacing:0.35px;text-transform:uppercase;}
.nba6bt5{font-size:12px;font-weight:normal;font-family:"Open Sans";-webkit-letter-spacing:0.35px;-moz-letter-spacing:0.35px;-ms-letter-spacing:0.35px;letter-spacing:0.35px;text-transform:uppercase;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.n10t1pgf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.n10t1pgf .nba6bt5:last-child{font-weight:500;}
.n1ozr2mk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;cursor:pointer;}.n1ozr2mk.disabled{opacity:0.5;}
.n1trp08j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-left:8px;}
.v1gyuqyd.n1pelsnz{margin-left:10px;margin-right:10px;}
.n1uejf4k.sx11gq7{position:relative;}
