@import "~@csstools/normalize.css";
@import "./variables";
@import "./typography";
@import "./animation";

* {
  box-sizing: border-box;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $background-color;
}

button {
  border: 0;
  background: none;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

body,
button,
input,
textarea {
  font-family: "Circular Pro";
  color: inherit;
  padding: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

a {
  color: $text-color;
  text-decoration: none;
}

.dark a {
  color: $text-color-primary;
}

.dark .icon.path {
  & path,
  & circle,
  & rect {
    fill: $text-color-primary;
  }
}

.dark .icon.stroke {
  & path,
  & circle,
  & rect {
    stroke: $text-color-primary;
  }
}

* {
  //sorry
  outline: 0;
}


